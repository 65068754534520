<template>
    <div class="logo">
        <div class="txt">
            欢迎登录沐心图书馆
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style scoped>
.logo{
    min-height: calc(100vh - 120px);
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
}
.txt{
    color: #858585;
    font-size: 30px;
    margin-left: 40%;
    margin-top: 25%;
}
</style>